import { ColDef } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api-service/api-service';

@Injectable({ providedIn: 'root' })
export class NetworkFociGridData {
  constructor (private apiService: ApiService) {}

  private _cachedColumnDefConfig: Array<ColDef>;

  // columnDefs
  public getGridConfig$ (clusterId: string): Observable<Array<ColDef>> {
    const query = `
      query NetworkFociGrid($clusterId: String!) {
        NetworkFociGridConfig(clusterId: $clusterId) {
          field
          headerName
          headerTooltip
          tooltipField
          filter
          filterParams
          width
          valueFormatter
          pinned
          lockPinned
        }
      }
    `;

    const payload = { query, fetchPolicy: 'no-cache', variables: { clusterId } };

    if (this._cachedColumnDefConfig) return of(this._cachedColumnDefConfig);
    else {
      return this.apiService.getStudentsGraphQL(payload).pipe(
        map(res => {
          const columnDefs = res.data.NetworkFociGridConfig;
          // deserialize JSON for ag-grid consumption -- ie: set filter value options
          return columnDefs.map((col: ColDef) => {
            col.filterParams = JSON.parse(col.filterParams);
            col.filterParams = {
              ...col.filterParams,
              buttons: ['clear'],
            };
            return col;
          });
        }),
        tap(colDefs => (this._cachedColumnDefConfig = colDefs)),
        catchError(() => of([])),
      );
    }
  }

  public getGridViewOptions$ (clusterId: string) {
    const query = `
      query NetworkGrid($clusterId: String!) {
        NetworkFociGridViewOptions (clusterId: $clusterId) {
          key
          human
          options
        }
      }`;

    const payload = { query, fetchPolicy: 'no-cache', variables: { clusterId } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => {
        const response = res.data.NetworkFociGridViewOptions;
        return response.map(({ key, human, options }) => ({ key, human, options: options.map((option) => ({ key: option, human: option })) }));
      }),
      catchError(() => of([])),
    );
  }

  public getGridData$ ({ request, columnDefs, gridView, clusterId, contextPartnerType }) {
    const columnFields = columnDefs.map(({ field }) => field);
    const tooltipFields = columnDefs.reduce((acc, { tooltipField }) => {
      if (tooltipField) acc.push(tooltipField);
      return acc;
    }, []);
    const fields = [...columnFields, ...tooltipFields];
    const query = `
      query NetworkGrid($agGridRequest: AgGridRequest!, $columnFields: [String!], $gridView: String!, $clusterId: String!, $contextPartnerType: String!) {
        NetworkFociGridData(agGridRequest: $agGridRequest, columnFields: $columnFields, gridView: $gridView, clusterId: $clusterId, contextPartnerType: $contextPartnerType) {
          ${fields}
        }
      }
  `;
    const payload = { query, fetchPolicy: 'no-cache', variables: { agGridRequest: request, columnFields: fields, gridView, clusterId, contextPartnerType } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => {
        return res.data.NetworkFociGridData.map((dataRow: any) => {
          return this.formatDataRowTooltips(dataRow);
        });
      }),
      catchError(() => of([])),
    );
  }

  public getGridCount$ ({ request, gridView, clusterId, contextPartnerType }):Observable<{count: number}> {
    const query = `
      query NetworkGrid($agGridRequest: AgGridRequest!, $gridView: String!, $clusterId: String!, $contextPartnerType: String!) {
        NetworkFociGridCount(agGridRequest: $agGridRequest, gridView: $gridView, clusterId: $clusterId, contextPartnerType: $contextPartnerType) {
          count
        }
      }
  `;
    // total or filtered
    const payload = { query, fetchPolicy: 'no-cache', variables: { agGridRequest: request, gridView, clusterId, contextPartnerType } };
    return this.apiService.getStudentsGraphQL(payload).pipe(
      map((res) => res.data.NetworkFociGridCount),
      catchError(() => of([])),
    );
  }

  public getGridDataAndCount$ ({ request, columnDefs, gridView, clusterId, contextPartnerType }) {
    return forkJoin([
      this.getGridData$({ request, columnDefs, gridView, clusterId, contextPartnerType }),
      this.getGridCount$({ request, gridView, clusterId, contextPartnerType }).pipe(map(res => res?.count)),
    ]);
  }

  private formatDataRowTooltips (dataRow: any) {
    for (const key in dataRow) {
      if (key.match('tooltip')) {
        if (dataRow[key] && dataRow[key].match(/\[n\]/)) { // regex looks for [n]
          const tooltext = dataRow[key].split('[n]');
          dataRow[key] = tooltext;
        };
      }
    }
    return dataRow;
  }
}
