import { Injectable } from '@angular/core';
import { IBarGraphData } from 'Src/ng2/network/network-mid-level/network-mid-level-viz/network-mid-level-viz.component';
import * as d3 from 'd3';
import { ILineGraphData, ILineGraphLegendItem } from '../../components/trends-viz/trends-viz.component';
import { DashboardCircleService } from '../dashboard-circle-service/dashboard-circle-service';
import STACKED_BAR_COLOR_CONFIG from './viz-stacked-bar-color-config.json';

export interface IDotPlotData extends IBarGraphData {
  secondaryGroup: any;
  wholeSchoolFocus: any;
}

/* istanbul ignore next */
@Injectable()
export class VizD3Service {
  constructor (
    private circleService: DashboardCircleService,
  ) {}

  get STACKED_BAR_COLORS () {
    return {
      // transfer school version of Chronic Absenteeism
      '80% or less attendance': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['1pos-0neu-4neg']].reverse(),
      },
      'Chronically absent students': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['1pos-0neu-4neg']].reverse(),
      },
      'Current term course scheduling status based on source system data': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['1pos-0neu-4neg']],
      },
      'Course planning status based on Portal plans': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['2pos-0neu-1neg']],
      },
      'Have Complete Grad Plans': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['2pos-1neu-2neg']],
      },
      'Upcoming Admin Regents Scheduling Status': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['2pos-0neu-3neg']],
      },
      'Regents Planning Status': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['3pos-0neu-1neg']],
      },
      'ML Students': {
        base: [...STACKED_BAR_COLOR_CONFIG.categorical],
      },
      'Latest MP course status': {
        base: [...STACKED_BAR_COLOR_CONFIG.sequential['1pos-0neu-3neg']],
      },
    };
  }

  getStackedBarColors ({ vizStackedBarKeys, focusName }): { [name: string]: d3.ScaleOrdinal<string, any> } {
    const colors = this.STACKED_BAR_COLORS[focusName].base;
    const baseColors = colors.map(color => `var(${color})`);
    const lightColors = baseColors.map((color) => this.circleService.shadeColor(color, STACKED_BAR_COLOR_CONFIG.shadeConfig.barOnHover / 100));
    const base = d3
      .scaleOrdinal()
      .domain(vizStackedBarKeys)
      .range(baseColors);

    const light = d3
      .scaleOrdinal()
      .domain(vizStackedBarKeys)
      .range(lightColors);

    return { base, light };
  }

  buildLineGraphVizLegend (
    legendItems: ILineGraphLegendItem[],
    vizLegend,
    vizWidth: number,
  ) : void {
    const legendHtml = legendItems.map(vizLegendItem => {
      const { displayName, color } = vizLegendItem;
      return this.getTableHtmlForTooltipAndLegend({ colorScale: color, displayName, direction: 'horizontal' });
    })
    .join('');

    this.createVizLegend({
      vizLegend,
      addTransition: false,
      legendHtml,
      vizWidth,
    });
  }

  buildDotPlot (
    d3Element: d3.Selection<SVGGElement, any, any, any>,
    colorScale: d3.ScaleOrdinal<string, any>,
    data: IDotPlotData[],
    x,
    y,
  ): d3.Selection<SVGCircleElement, IDotPlotData, SVGGElement, any> {
    const dots: any = d3Element.selectAll('circle').data(data);
    dots.exit().remove();

    const radius = data.length > 500 ? data.length < 1000 ? 3 : 2 : 4;

    const newDots = d3Element.selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', d => x(d.group) + x.bandwidth() / 2)
      .attr('cy', d => y(d.value))
      .attr('r', radius)
      .style('fill', (d: any): any => colorScale(d.secondaryGroup));

    d3Element.selectAll('circle')
      .style('opacity', 0)
      .transition()
      .duration(500)
      .style('opacity', 1);

    return newDots;
  }

  initDotPlotListeners (
    dots: d3.Selection<SVGCircleElement, IDotPlotData, SVGGElement, any> ,
    labels: d3.Selection<d3.BaseType, IDotPlotData, SVGGElement, any>,
    emitHoveredBar: (d: IDotPlotData) => void,
    emitDotPlotHoveredBar: (d: IDotPlotData) => void,
    onLabelHover: (d: IDotPlotData) => void,
    onDotHover: (d: IDotPlotData) => void,
  ): void {
    dots.on('mouseover', (e: MouseEvent, d: IDotPlotData) => {
      emitDotPlotHoveredBar(d);
      onDotHover(d);
    });

    labels.on('mouseover', (e: MouseEvent, d: IDotPlotData) => {
      emitHoveredBar(d);
      onLabelHover(d);
    });

    dots.on('mouseout', () => {
      emitDotPlotHoveredBar(null);
    });

    labels.on('mouseout', () => {
      emitHoveredBar(null);
    });
  }

  getDotPlotColorScale (data: IDotPlotData[]): d3.ScaleOrdinal<string, any> {
    const vizLegendItems = data.reduce(
      (acc, data) => {
        if (!acc.includes(data.secondaryGroup)) {
          acc.push(data.secondaryGroup);
        }
        return acc;
      }, []
    );

    return d3
      .scaleOrdinal()
      .domain(vizLegendItems)
      .range([
        'rgb(0, 176, 202)', 'rgb(25, 71, 125)', 'rgb(102, 45, 145)', 'rgb(0, 155, 72)', 'rgb(10, 196, 172)', 'rgb(255, 92, 143)',
        'rgb(194, 62, 77)', 'rgb(255, 196, 0)', 'rgb(104, 61, 13)', 'rgb(135, 168, 0)', 'rgb(119, 102, 206)', 'rgb(188, 49, 188)', 'rgb(225, 107, 48)'
      ]);
  }

  buildDotPlotVizLegend (
    colorScale: d3.ScaleOrdinal<string, any>,
    vizLegend: d3.Selection<HTMLElement, any, any, any>,
    vizWidth: number,
  ): void {
    const legendHtml = colorScale.domain()
      .map(vizLegendItem => {
        const displayName = vizLegendItem;
        const direction = 'horizontal';
        const color = colorScale(vizLegendItem);
        return this.getTableHtmlForTooltipAndLegend({ colorScale: color, displayName, direction });
      })
      .join('');

    this.createVizLegend({
      vizLegend,
      addTransition: true,
      legendHtml,
      vizWidth,
    });
  }

  buildMouseTarget (
    mouseTarget,
    width: number,
    marginLeft: number,
    height: number,
  ): d3.Selection<SVGGElement, any, any, any> {
    return mouseTarget
      .append('rect')
      .attr('class', 'mouseTarget')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width - marginLeft)
      .attr('height', height)
      .style('opacity', 0);
  }

  createXScale (data, margin, width): d3.ScaleBand<string> {
    return d3
      .scaleBand()
      .domain(data.map((d: any) => d.group))
      .range([margin.left, width - margin.right])
      .paddingInner(0.3)
      .paddingOuter(0.7);
  }

  createYScale (yAxisDomainMax, margin, height): d3.ScaleLinear<any, any> {
    return d3
      .scaleLinear()
      .domain([0, yAxisDomainMax])
      .nice()
      .range([height - margin.bottom, margin.top]);
  }

  createLineGraphXScale (data, margin, width: number): d3.ScalePoint<string> {
    return d3
      .scalePoint()
      .domain(d3.map(data, (d: any) => d.dateString))
      .range([margin.left + 30, width - margin.right - 30]);
  }

  createYScaleNoData (margin, height): d3.ScaleLinear<any, any> {
    return d3
      .scaleLinear()
      .domain([0, 5])
      .nice()
      .range([height - margin.bottom, margin.top]);
  }

  createLineGraphYScale (axisDomainMin = 0, axisDomainMax = 100, margin, height): d3.ScaleLinear<any, any> {
    return d3
      .scaleLinear()
      .domain([axisDomainMin, axisDomainMax])
      .range([height - margin.bottom, margin.top]);
  }

  createXAxis (height, margin, x, g): d3.Selection<SVGGElement, any, any, any> {
    return g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .attr('class', 'x-axis')
      .call(d3.axisBottom(x).tickSizeOuter(0))
      .call(g => g.select('.domain').remove());
  }

  // eslint-disable-next-line no-undef
  createYAxis (width, margin, y, g, tickFormatter): d3.Selection<SVGGElement, any, any, any> {
    const yDomain = y.domain();
    const tickCount = yDomain[1] - yDomain[0] < 5 ? yDomain[1] - yDomain[0] : 5;
    return g
      .attr('transform', `translate(${margin.left},0)`)
      .attr('class', 'y-axis')
      .call(
        d3
          .axisLeft(y)
          .ticks(tickCount)
          .tickSize(-width + margin.left + margin.right)
          .tickFormat(tickFormatter),
      )
      .call(g => g.select('.domain').remove());
  }

  createYAxisNoData (width, margin, yNoData, g): d3.Selection<SVGGElement, any, any, any> {
    return g
      .attr('transform', `translate(${margin.left},0)`)
      .attr('class', 'y-axis')
      .call(
        d3
          .axisLeft(yNoData)
          .ticks(5)
          .tickFormat(d => {
            return '';
          })
          .tickSize(-width + margin.left + margin.right),
      )
      .call(g => g.select('.domain').remove());
  }

  createLineGraphXAxis (height, margin, x, g): d3.Selection<SVGGElement, any, any, any> {
    const tickVals = this.getXAxisTickValues(x);
    return g
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .attr('class', 'x-axis')
      .transition()
      .duration(1000)
      .call(d3
        .axisBottom(x)
        .tickValues(tickVals),
      )
      .call(g => g.select('.domain').remove());
  }

  getXAxisTickValues (x) {
    let tickValues = x.domain();
    if (x.domain().length > 12) {
      tickValues = x.domain().filter((d, i) => i % 2 === 0);
    }
    return tickValues;
  }

  getAxisDomainMinMax (data, axisDomainPadding: number): { axisDomainMin: number, axisDomainMax: number } {
    const min = parseInt(d3.min(data, (d: any) => d.value)) - axisDomainPadding;
    const max = parseInt(d3.max(data, (d: any) => d.value)) + axisDomainPadding;
    const axisDomainMin = min < 0 ? 0 : min;
    const axisDomainMax = max > 100 ? 100 : max;
    return { axisDomainMin, axisDomainMax };
  }

  createVizTitle (vizTitle, addTransition, title): void {
    vizTitle
      .selectAll('.viz-title')
      .remove()
      .exit();
    vizTitle
      .append('text')
      .attr('class', 'viz-title')
      .attr('x', 20)
      .attr('y', 15)
      .text(title)
      .style('opacity', 0)
      .transition()
      .duration(addTransition ? 1000 : 0)
      .style('opacity', 0.6);
  }

  createVizDateStamp (dateStampElement, addTransition, dateStampText, width, margin): void {
    dateStampElement.selectAll('.viz-date-stamp').remove().exit();
    dateStampElement
      .append('text')
      .attr('class', 'viz-date-stamp')
      .attr('x', width - margin.right)
      .attr('y', 15)
      .text(dateStampText)
      .style('opacity', 0)
      .transition()
      .duration(addTransition ? 1000 : 0)
      .style('opacity', 1);
  }

  createVizLegend ({ vizLegend, addTransition, legendHtml, vizWidth }): void {
    vizLegend.html(`${legendHtml}`);
    vizLegend.style('opacity', 0);
    if (legendHtml) {
      const legendDim = vizLegend.node().getBoundingClientRect();
      const legendWidth = legendDim.width;
      const marginLeft = (vizWidth - legendWidth) / 2;
      vizLegend
        .transition()
        .duration(addTransition ? 1000 : 0)
        .style('opacity', 1)
        .style('transform', `translate(${Math.round(marginLeft) + 12}px, 0px)`);
    }
  }

  getRectanglesPreTransition (x, y, d): string {
    const rx = d.value > 0 ? 4 : 0;
    const ry = d.value > 0 ? 4 : 0;
    return `
      M${x(d.group)},${y(0)}
      a${rx},${ry} 0 0 1 ${rx},${-ry}
      h${x.bandwidth() - 2 * rx}
      a${rx},${ry} 0 0 1 ${rx},${ry}
      v${0}
      h${-x.bandwidth()}
      Z
    `;
  }

  getRectanglesPostTransition (x, y, d, height, margin): string {
    const rx = d.value > 0 ? 4 : 0;
    const ry = d.value > 0 ? 4 : 0;
    return `
      M${x(d.group)},${y(d.value) + ry}
      a${rx},${ry} 0 0 1 ${rx},${-ry}
      h${x.bandwidth() - 2 * rx}
      a${rx},${ry} 0 0 1 ${rx},${ry}
      v${height - margin.bottom - y(d.value) - ry}
      h${-x.bandwidth()}
      Z
    `;
  }

  getStackedBarsPreTransition (x, y, d): string {
    const rx = 4;
    const ry = 4;
    return `
      M${x(d.data.group)},${y(0)}
      a${rx},${ry} 0 0 1 ${rx},${-ry}
      h${x.bandwidth() - 2 * rx}
      a${rx},${ry} 0 0 1 ${rx},${ry}
      v${0}
      h${-x.bandwidth()}
      Z
    `;
  }

  // Note from CMAC:
  // We have to build the top section of the stacked bars (and the bars in the regular viz) using a path rather than a rect since it has curved edges.
  // This logic allows for just two of the four corners to be curved.
  // In the stacked bars, rx and ry will be 0 if it's not the top section so they won't have curved corners.
  getStackedBarsPostTransition (x, y, d, height, margin, rx, ry, gap = 0): string {
    return `
      M${x(d.data.group)},${y(d[1]) + ry}
      a${rx},${ry} 0 0 1 ${rx},${-ry}
      h${x.bandwidth() - 2 * rx}
      a${rx},${ry} 0 0 1 ${rx},${ry}
      v${height - margin.bottom - y(d[1] - d[0]) - ry - gap}
      h${-x.bandwidth()}
      Z
    `;
  }

  displayGapBetweenStacks (d: d3.SeriesPoint<any>): boolean {
    const [start, end] = d;
    const bottomStack = start === 0;
    const singleStack = start === 100 && end === 100;
    return !bottomStack && !singleStack;
  }

  getTableHtmlForTooltipAndLegend ({ colorScale, displayValue = null, displayName, direction }): string {
    let html = '';
    switch (direction) {
      case 'vertical':
        html = `<span class='circle' style='background:${colorScale}'></span>
                <span *ngIf="displayValue" class='value'>${displayValue}</span>
                <span class='group'>${displayName}</span>`;
        break;
      case 'horizontal':
        html = displayValue
          ? `<span>
              <span class='circle' style='background:${colorScale}'></span>
              <span class='value'>${displayValue}</span>
              <span class='group'>${displayName}</span>
            </span>`
          : `<span>
              <span class='circle' style='background:${colorScale}'></span>
              <span class='group'>${displayName}</span>
            </span>`;
        break;
      default:
        break;
    }
    return html;
  }
}
