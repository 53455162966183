<page-banner [meta]="pageBannerMeta" *ngIf="isContentLoaded">
  <div class="page-banner" top-left>
    <ng-container *ngIf="!isLogoutEnabled">
      <exit-view [exitState]="exitState" [icon]="'close-large-white'" id="close-button" isInverted="true"> </exit-view>
    </ng-container>
    {{gridName}}
    <nv-icon [name]="'caret-right-white'"></nv-icon>
    <span> {{ selectedGridView?.human }} </span>
    <div class="autosave" *ngIf="isCustomView && isGridViewEnabled && isGridViewChanged" nvTooltip [tooltipData]="autoSaveTooltip">
      <nv-icon [name]="autoSaveIcon"></nv-icon>
      {{ autoSaveText }}
    </div>
    <div
      class="autosave"
      *ngIf="!isCustomView && isGridViewChanged && isGridViewEnabled"
      nvTooltip
      [tooltipData]="unsavedChangesTooltip"
    >
      <nv-icon [name]="unsavedChangesIcon"></nv-icon> {{ unsavedChangesText }}
    </div>
  </div>
  <ng-container top-right *ngIf="isLogoutEnabled">
    <nvps-log-out [portalType]="portalType" [type]="'BUTTON'"></nvps-log-out>
  </ng-container>
</page-banner>

<div class="page-banner-skeleton" *ngIf="!isContentLoaded">
  <ngx-skeleton-loader [theme]="skeletonBanner" align-content="center"></ngx-skeleton-loader>
</div>

<mat-sidenav-container class="grid-sidenav-container" hasBackdrop="false">
  <mat-sidenav
    *ngIf="isGridViewEnabled"
    mode="side"
    disableClose
    opened
    [ngClass]="{
      'sidenav-closed': isSidenavClosed,
      'expand-ed-user': isExpandEd,
    }"
  >
    <nv-icon
      *ngIf="isContentLoaded"
      class="sidenav-toggle-icon"
      [name]="sidenavIcon"
      (click)="onToggleSidenav()"
    ></nv-icon>
    <ngx-skeleton-loader
      *ngIf="!isContentLoaded"
      class="skeleton-grid-view-icon"
      [theme]="skeletonIconTheme"
    ></ngx-skeleton-loader>
    <div class="mat-sidenav-expanded" *ngIf="!isSidenavClosed && isContentLoaded">
      <nv-sidebar-list
        customTitle="My views"
        [listData]="myViewsListData"
        [selectedKey]="selectedGridViewKey"
        [emptyMessage]="noCustomViewText"
        [titleIconTooltip]="customViewIconTooltip"
        [titleIcon]="portalViewIcon"
        [visibleOptionsLimit]="visibleOptionsLimit"
        (sidebarListItemSelect)="onSidenavClick($event)"
        (iconMenuSelect)="onIconMenuSelect($event)"
        titleButtonLabel="New"
        (titleButtonClick)="onClickedCreateView($event)"
      ></nv-sidebar-list>

      <nv-sidebar-list
        *ngIf="portalViewsListData.length"
        customTitle="Portal views"
        [expandedNodes]="openSidebarItems"
        [listData]="portalViewsListData"
        [titleIconTooltip]="portalViewIconTooltip"
        [titleIcon]="portalViewIcon"
        [selectedKey]="selectedGridViewKey"
        (sidebarListItemSelect)="onSidenavClick($event)"
        (iconMenuSelect)="onIconMenuSelect($event)"
      ></nv-sidebar-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [ngStyle]="{ 'margin-left.px': isSidenavClosed ? sidenavClosedMargin: sidenavOpenMargin }">
    <div class="grid-page-container">
      <div class="grid-container">
        <div class="grid-header">
          <div
            class="grid-toolbar"
            *ngIf="isContentLoaded"
            [ngClass]="{ 'batch-action-mode-on': batchActionMode, 'batch-action-mode-off': !batchActionMode }"
          >
            <ng-template [ngIf]="!batchActionMode">
              <div class="table-controls">
                <grid-table-controls
                  [activeNetworkExternalFilterOptions]="activeNetworkExternalFilterOptions"
                  [columnDefs]="columnDefs"
                  [gridData$]="gridTableControlData$.asObservable()"
                  [networkExternalFilterOptions]="networkExternalFilterOptions"
                  (onEditColumns)="onEditColumns()"
                  (gridTableControlAction)="onGridTableControlAction($event)"
                  (gridTableControlDataRequest)="onGridTableControlDataRequest($event)"
                  (networkExternalFilterOptionChange)="onNetworkExternalFilterOptionChange($event)"
                >
                </grid-table-controls>
              </div>
              <div class="actions">
                <nv-actions
                  *ngIf="gridActions"
                  [actions]="gridActions"
                  (clickAction)="onBatchActions($event)"
                ></nv-actions>
              </div>
            </ng-template>
            <ng-template [ngIf]="batchActionMode">
              <div class="action-selection">
                <div class="selected-text">{{ numRowsSelected }} selected</div>
                <div class="vertical-bar"></div>
                <nv-button
                  class="action-primary-button"
                  type="primary"
                  size="small"
                  [disabled]="numRowsSelected === 0"
                  (clickButton)="onClickedAction(selectedAction.key)"
                  >{{ selectedAction.human }}
                </nv-button>
                <nv-button [icon]="'close-small-blue'" size="small" type="secondary" (click)="onCancel()"
                  >Cancel</nv-button
                >
              </div>
            </ng-template>

            <div class="search">
              <nv-textbox
                [textControl]="filterFormControl"
                [placeholder]="searchBoxPlaceHolder"
                icon="search-large-black"
                [hasClearBtn]="true"
                (clearValue)="onClear()"
              ></nv-textbox>
            </div>

            <!-- Only appears in Portal views (does not appear in custom views) -->
            <div class="save-grid-view-button" *ngIf="!isCustomView && isGridViewEnabled">
              <nv-button
                class="action-primary-button"
                type="primary"
                size="small"
                [disabled]="!isGridViewChanged"
                (clickButton)="saveAsNewGridView()"
                >Save to My Views
              </nv-button>
            </div>
          </div>

          <ng-template [ngIf]="!isContentLoaded">
            <div class="grid-toolbar-skeleton-loader">
              <ngx-skeleton-loader
                class="skeleton-table-controls grid-toolbar-skeleton-block"
                [theme]="skeletonGridToolbar"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                class="skeleton-actions grid-toolbar-skeleton-block"
                [theme]="skeletonGridToolbar"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                class="skeleton-search grid-toolbar-skeleton-block"
                [theme]="skeletonGridToolbar"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                class="skeleton-save-button grid-toolbar-skeleton-block"
                [theme]="skeletonGridToolbar"
              ></ngx-skeleton-loader>
            </div>
          </ng-template>
        </div>

        <ag-grid-angular
          class="ag-theme-alpine"
          [columnDefs]="columnDefs"
          [getMainMenuItems]="getMainMenuItems"
          [gridOptions]="gridOptions"
          [modules]="modules"
          [rowModelType]="rowModelType"
          (bodyScroll)="onBodyScroll($event)"
          (cellClicked)="onCellClicked($event)"
          (columnPinned)="saveGridState($event)"
          (columnVisible)="saveGridState($event)"
          (dragStopped)="saveGridState($event)"
          (filterChanged)="onFilterChanged($event)"
          (firstDataRendered)="onFirstDataRendered()"
          (columnEverythingChanged)="onGridColumnsChanged($event)"
          (gridReady)="onGridReady($event)"
          (rowSelected)="onRowSelected($event)"
          (sortChanged)="saveGridState($event)"
          (selectionChanged)="onSelectionChanged($event)"
          (paginationChanged)="onPaginationChanged($event)"
          (viewportChanged)="onViewPortChanged($event)"
        ></ag-grid-angular>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
